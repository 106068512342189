@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext");

:root {
  --mainTextColor-light: #000;
  --secondaryTextColor-light: rgb(51 51 51);
  --shadowText-light: rgba(40, 40, 40, 0.444);
  --mainLinkColor-light: #872222;
  --main-border-color: #dadada;
  --mainBgColor-light: #e6e4e4;
  --secondaryBgColor-light: #ffffff;
  --card-bg-color: #f0f4f191;
  --menu-drawer-bg-color: #e6e4e4;
  --popup-bg-color: #e6e4e4;
  --shadow-card-color: #84868413;
  --button-bg-color: #872222;
  --button-text-color: #e6e4e4;
  --button-hover-color: #571616;
  --button-hover-0: #571616;
  --button-hover-75-100: #2b7d3700;
  --button-active-0: #571616;
  --button-active-75-100: #2b7d3700;
  --main-text-color: #872222;
  --secondary-text-color: #0b0b0b;
  --shadow-main-text-color: #0000002e;
  --shadow-secondary-text-color: #872222;
  --dialog-bg-color: #25252580;
  --general-dark: #000000;
  --general-light: #e6e4e4;
  --main-color: #872222;
  --green: #4b9933;
  --blue: #373bb2c0;
  --red: #f43e3e93;

  --mainTextColor-dark: #fff;
  --secondaryTextColor-dark: #c2a818;
  --shadowText-dark: rgba(230, 228, 228, 0.529);
  --mainLinkColor-dark: #fff;
  --mainBorderColor-dark: #2b3031;
  --mainBgColor-dark: #131415;
  --secondaryBgColor-dark: rgb(230, 228, 228);

  --mainTextColor: var(--mainTextColor-light);
  --secondaryTextColor: var(--secondaryTextColor-light);
  --shadowText: var(--shadowText-light);
  --mainLinkColor: var(--mainLinkColor-light);
  --mainBgColor: var(--mainBgColor-light);
  --secondaryBgColor: var(--secondaryBgColor-light);

  --font: Open Sans;
}
